export const data = [
  {
    nome: "Sezione I",
    descrizione:
      "solisti scuola secondaria di Primo grado e a indirizzo musicale e non",
    categorie: [
      { nome: "A1", descrizione: "Pianoforte prima media", scuola: "media" },
      { nome: "A2", descrizione: "Pianoforte seconda media", scuola: "media" },
      { nome: "A3", descrizione: "Pianoforte terza media", scuola: "media" },
      { nome: "B1", descrizione: "Archi prima media", scuola: "media" },
      { nome: "B2", descrizione: "Archi seconda media", scuola: "media" },
      { nome: "B3", descrizione: "Archi terza media", scuola: "media" },
      { nome: "C1", descrizione: "Fiati prima media", scuola: "media" },
      { nome: "C2", descrizione: "Fiati seconda media", scuola: "media" },
      { nome: "C3", descrizione: "Fiati terza media", scuola: "media" },
      { nome: "D1", descrizione: "Chitarra prima media", scuola: "media" },
      { nome: "D2", descrizione: "Chitarra seconda media", scuola: "media" },
      { nome: "D3", descrizione: "Chitarra terza media", scuola: "media" },
      { nome: "E1", descrizione: "Percussioni prima media", scuola: "media" },
      { nome: "E2", descrizione: "Percussioni seconda media", scuola: "media" },
      { nome: "E3", descrizione: "Percussioni terza media", scuola: "media" },
    ],
  },
  {
    nome: "Sezione II",
    descrizione: "solisti Licei Musicali e non",
    categorie: [
      { nome: "A1", descrizione: "Pianoforte biennio", scuola: "liceo" },
      { nome: "A2", descrizione: "Pianoforte triennio", scuola: "liceo" },
      { nome: "B1", descrizione: "Archi biennio", scuola: "liceo" },
      { nome: "B2", descrizione: "Archi triennio", scuola: "liceo" },
      { nome: "C1", descrizione: "Fiati biennio", scuola: "liceo" },
      { nome: "C2", descrizione: "Fiati triennio", scuola: "liceo" },
      { nome: "D1", descrizione: "Chitarra biennio", scuola: "liceo" },
      { nome: "D2", descrizione: "Chitarra triennio", scuola: "liceo" },
      { nome: "E1", descrizione: "Percussioni biennio", scuola: "liceo" },
      { nome: "E2", descrizione: "Percussioni triennio", scuola: "liceo" },
      { nome: "F1", descrizione: "Canto biennio", scuola: "liceo" },
      { nome: "F2", descrizione: "Canto triennio", scuola: "liceo" },
    ],
  },

  {
    nome: "Sezione III",
    descrizione:
      "musica da Camera da 2 a 16 elementi – Scuole medie ad indirizzo musicale e non – Licei musicali e non",
    categorie: [
      { nome: "A1", descrizione: "Prima media", scuola: "media" },
      { nome: "A2", descrizione: "Seconda media", scuola: "media" },
      { nome: "A3", descrizione: "Terza media", scuola: "media" },
      { nome: "B1", descrizione: "Liceo biennio", scuola: "liceo" },
      { nome: "B2", descrizione: "Liceo triennio", scuola: "liceo" },
    ],
  },
  {
    nome: "Sezione IV",
    descrizione:
      "Orchestre Scuole medie ad indirizzo musicale e non – Licei musicali e non",
    categorie: [
      { nome: "A", descrizione: "Alunni scuola media", scuola: "media" },
      { nome: "B", descrizione: "Alunni Liceo", scuola: "liceo" },
    ],
  },
];

export default data;
