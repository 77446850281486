import React, { useState, useEffect } from "react";
import { Space, Table, PageHeader, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios, { Axios } from "axios";
import sectionsData from "../../data/sezioni-categorie";
import { navigate } from "gatsby";
import "../../styles/main.sass";
import "antd/dist/antd.css";

function OnlineTable() {
  const [sezione, setSezione] = useState(false);
  const [step, setStep] = useState(0);

  function CategoryButton({ index, name, description, scuola }) {
    if (step === 0) {
      return (
        <button
          onClick={() => {
            setSezione(index);
            setStep(1);
          }}
        >
          <span>{name} </span>
          {description}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            navigate(
              "/dashboard/online-table/?sez=" + sezione + "&cat=" + index
            );
          }}
        >
          <span>{name} </span>
          {description}
        </button>
      );
    }
  }

  return (
    <>
      <PageHeader
        style={{
          marginBottom: 40,
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        }}
        ghost={false}
        onBack={() => (window.location.href = "/dashboard")}
        title="Dashboard"
      ></PageHeader>
      {step === 0 ? (
        <main>
          <div className="catSelect-w">
            <h2>Seleziona una sezione</h2>
            {sectionsData.map((item, index) => {
              return (
                <CategoryButton
                  index={index}
                  name={item.nome}
                  description={item.descrizione}
                  key={index}
                />
              );
            })}
          </div>
        </main>
      ) : step === 1 ? (
        <main>
          <div className="catSelect-w">
            <h2>Seleziona una categoria</h2>
            {sectionsData[sezione].categorie.map((item, index) => {
              return (
                <CategoryButton
                  index={index}
                  name={item.nome}
                  description={item.descrizione}
                  scuola={item.scuola}
                  key={index}
                />
              );
            })}
          </div>
        </main>
      ) : (
        <div>loading</div>
      )}
    </>
  );
}

export default OnlineTable;
